<template>
  <baseCard v-if="showBerekening" class="max-h-full overflow-y-auto">
    <!-- verbruik -->
    <p class="text-l text-slate-500 my-2 font-bold">Verbruik</p>
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3"></p>
      <p class="basis-1/3 font-bold">Huidig</p>
      <p class="basis-1/3 font-bold">Nieuw</p>
    </div>

    <!-- totale behoefte gas -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Gas</p>
      <p class="basis-1/3">{{ useFilter.filter(huidig.result.data_total.verbruik_totaal_m3, 'round') }} m3</p>
      <p class="basis-1/3">{{ useFilter.filter(nieuw.result.data_total.verbruik_totaal_m3, 'round') }} m3</p>
    </div>

    <!-- totale behoefte warmte -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Warmte</p>
      <p class="basis-1/3">{{ useFilter.filter(huidig.result.data_total.verbruik_totaal_gj, 'round') }} GJ</p>
      <p class="basis-1/3">{{ useFilter.filter(nieuw.result.data_total.verbruik_totaal_gj, 'round') }} GJ</p>
    </div>

    <!-- totale behoefte elektriciteit -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Elektriciteit: totale behoefte</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig.result.data_total.eigen_behoefte_totaal, 'round') }}
        kWh
      </p>
      <p class="basis-1/3">{{ useFilter.filter(nieuw.result.data_total.eigen_behoefte_totaal, 'round') }} kWh</p>
    </div>

    <!-- Ontrekking van het net -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Elektriciteit: ontrekking van het net</p>
      <p class="basis-1/3">{{ useFilter.filter(huidig.result.data_total.van_het_net_kwh, 'round') }} kWh</p>
      <p class="basis-1/3">{{ useFilter.filter(nieuw.result.data_total.van_het_net_kwh, 'round') }} kWh</p>
    </div>

    <!-- Productie -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Elektriciteit: productie</p>
      <p class="basis-1/3">{{ useFilter.filter(huidig.result.data_total.opwekking_totaal_kwh, 'round') }} kWh</p>
      <p class="basis-1/3">{{ useFilter.filter(nieuw.result.data_total.opwekking_totaal_kwh, 'round') }} kWh</p>
    </div>

    <!-- Teruglevering -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Elektriciteit: teruglevering aan het net</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig.result.data_total.teruglevering_totaal_kwh, 'round') }}
        kWh
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(nieuw.result.data_total.teruglevering_totaal_kwh, 'round') }}
        kWh
      </p>
    </div>

    <!-- Vermogens per systeem -->
    <p class="text-l text-slate-500 my-2 font-bold">Vermogens per systeem</p>
    <div class="flex flex-row text-sm text-slate-500 mb-4">
      <p class="basis-1/3"></p>
      <p class="basis-1/3 font-bold">Huidig</p>
      <p class="basis-1/3 font-bold">Nieuw</p>
    </div>

    <!-- systemen -->
    <div
      class="flex flex-row text-sm text-slate-500 mb-4"
      v-for="(klimaatzoneNaam, klimaatzoneIndex) in uniekeKlimaatzones"
      :key="klimaatzoneIndex"
    >
      <div class="basis-1/3">{{ klimaatzoneNaam }}</div>
      <div class="basis-1/3" v-if="klimaatzoneNaam in huidig.result.data_total.klimaatzones">
        <div
          class="flex justify-between pr-8"
          v-for="(systeem, index) in huidig.result.data_total.klimaatzones[klimaatzoneNaam].afgiftevermogen_summary
            .systemen"
          :key="index"
        >
          <p>{{ systeem.type }}</p>
          <p>{{ useFilter.filter(systeem.vermogen, 'round2') }} kW</p>
        </div>
      </div>
      <div class="basis-1/3" v-else>
        <p>-</p>
        <p></p>
      </div>
      <div class="basis-1/3" v-if="klimaatzoneNaam in nieuw.result.data_total.klimaatzones">
        <div
          class="flex justify-between pr-8"
          v-for="(systeem, index) in nieuw.result.data_total.klimaatzones[klimaatzoneNaam].afgiftevermogen_summary
            .systemen"
          :key="index"
        >
          <p>{{ systeem.type }}</p>
          <p>{{ useFilter.filter(systeem.vermogen, 'round2') }} kW</p>
        </div>
      </div>
      <div class="basis-1/3" v-else>
        <p>-</p>
        <p></p>
      </div>
    </div>

    <!-- Streefwaarden woningisolatie -->
    <p class="text-l text-slate-500 my-2 font-bold">Standaardwaarde voor woningisolatie (indicatief)</p>
    <div class="flex flex-row text-sm text-slate-500 mb-4">
      <p class="basis-1/3"></p>
      <p class="basis-1/3 font-bold">Huidig</p>
      <p class="basis-1/3 font-bold">Nieuw</p>
    </div>

    <!-- woningtype -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Eistype</p>
      <p class="basis-1/3">
        {{ huidig20.result.data_total.streefwaarden_woningisolatie_woningtype }}
        {{ huidig20.result.data_total.streefwaarden_woningisolatie_era }}
      </p>
      <p class="basis-1/3">
        {{ nieuw20.result.data_total.streefwaarden_woningisolatie_woningtype }}
        {{ nieuw20.result.data_total.streefwaarden_woningisolatie_era }}
      </p>
    </div>

    <!-- Verliesoppervlakte -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Verliesoppervlakte (Als)</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig20.result.data_total.streefwaarden_woningisolatie_als, 'round2') }}
        m2
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(nieuw20.result.data_total.streefwaarden_woningisolatie_als, 'round2') }}
        m2
      </p>
    </div>

    <!-- Gebruiksoppervlakte -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Gebruiksoppervlakte (Ag)</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig20.result.data_total.totaal_gebruiksoppervlakte, 'round2') }}
        m2
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(nieuw20.result.data_total.totaal_gebruiksoppervlakte, 'round2') }}
        m2
      </p>
    </div>

    <!-- compactheid -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Compactheid (Als/Ag)</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig20.result.data_total.streefwaarden_woningisolatie_compactheid, 'round2') }}
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(nieuw20.result.data_total.streefwaarden_woningisolatie_compactheid, 'round2') }}
      </p>
    </div>

    <!-- netto warmtevraag -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Netto warmtevraag</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig20.result.data_total.streefwaarden_woningisolatie_netto_warmtevraag, 'round') }}
        kWh/m2/jaar
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(nieuw20.result.data_total.streefwaarden_woningisolatie_netto_warmtevraag, 'round') }}
        kWh/m2/jaar
      </p>
    </div>

    <!-- max netto warmtevraag -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Eis</p>
      <p class="basis-1/3">
        {{ useFilter.filter(huidig20.result.data_total.streefwaarden_woningisolatie_max_netto_warmtevraag, 'round') }}
        kWh/m2/jaar
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(nieuw20.result.data_total.streefwaarden_woningisolatie_max_netto_warmtevraag, 'round') }}
        kWh/m2/jaar
      </p>
    </div>

    <!-- Voldoet -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Voldoet</p>
      <p class="basis-1/3">
        {{ huidig20.result.data_total.streefwaarden_woningisolatie_voldoet }}
      </p>
      <p class="basis-1/3">
        {{ nieuw20.result.data_total.streefwaarden_woningisolatie_voldoet }}
      </p>
    </div>

    <p class="text-l text-slate-500 my-2 font-bold">Kosten</p>

    <!-- overzicht energiekosten -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3"></p>
      <p class="basis-1/3 font-bold">Huidig</p>
      <p class="basis-1/3 font-bold">Nieuw</p>
    </div>

    <!-- kosten per maand eerste jaar -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Energiekosten per maand (jaar 1)</p>
      <p class="basis-1/3">
        {{
          useFilter.filter(maatregelenHandler.costs.value.energiekosten_per_maand_eerste_jaar_huidig, 'currencyRound')
        }}
      </p>
      <p class="basis-1/3">
        {{
          useFilter.filter(maatregelenHandler.costs.value.energiekosten_per_maand_eerste_jaar_nieuw, 'currencyRound')
        }}
      </p>
    </div>

    <!-- kosten per jaar eerste jaar -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Energiekosten per jaar (jaar 1)</p>
      <p class="basis-1/3">
        {{
          useFilter.filter(maatregelenHandler.costs.value.energiekosten_per_jaar_eerste_jaar_huidig, 'currencyRound')
        }}
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.energiekosten_per_jaar_eerste_jaar_nieuw, 'currencyRound') }}
      </p>
    </div>

    <!-- kosten na 20 jaar -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Energiekosten na 20 jaar</p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.energiekosten_na_20_jaar_huidig, 'currencyRound') }}
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.energiekosten_na_20_jaar_nieuw, 'currencyRound') }}
      </p>
    </div>

    <!-- CO2 uitstoot per jaar -->
    <div class="flex flex-row text-sm text-slate-500 mb-4">
      <p class="basis-1/3">CO2-uitstoot per jaar</p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.co2_uitstoot_per_jaar_huidig, 'round') }} kg CO2
      </p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.co2_uitstoot_per_jaar_nieuw, 'round') }} kg CO2
      </p>
    </div>

    <!-- terugverdientijd -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Netto investering</p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.toaal_investering, 'currencyRound') }}
      </p>
      <p class="basis-1/3"></p>
    </div>

    <!-- co2 reductie na 20 jaar -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Co2-reductie na 20 jaar</p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.co2_reductie_na_20_jaar, 'round') }} kg CO2
      </p>
      <p class="basis-1/3"></p>
    </div>

    <!-- Geldbesparing na 20 jaar -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Geldbesparing na 20 jaar</p>
      <p class="basis-1/3">
        {{ useFilter.filter(maatregelenHandler.costs.value.geldbesparing_na_20_jaar, 'currencyRound') }}
      </p>
      <p class="basis-1/3"></p>
    </div>

    <!-- terugverdientijd -->
    <div class="flex flex-row text-sm text-slate-500 mb-4">
      <p class="basis-1/3">Terugverdientijd</p>
      <p class="basis-1/3">{{ maatregelenHandler.costs.value.terugverdientijd }} Jaar</p>
      <p class="basis-1/3"></p>
    </div>

    <p class="text-l text-slate-500 my-2 font-bold">Financiering (annuïteitenfinanciering)</p>
    <hypotheekBox :handler="hypotheekHandler" :maatregelenhandler="maatregelenHandler" />

    <div class="max-w-screen-lg mx-auto">
      <vue-highcharts
        class="mt-16"
        v-if="!loadingState"
        type="chart"
        :options="grafiekenHandler.options_energiekosten"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="root"
      />

      <vue-highcharts
        class="mt-16"
        v-if="!loadingState"
        type="chart"
        :options="grafiekenHandler.options_energiekosten_cumulatief"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="root"
      />

      <vue-highcharts
        class="mt-16"
        v-if="!loadingState"
        type="chart"
        :options="grafiekenHandler.options_energiegebruik"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="root"
      />

      <vue-highcharts
        class="mt-16"
        v-if="!loadingState"
        type="chart"
        :options="grafiekenHandler.options_co2"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="root"
      />
    </div>

    <div v-if="hypotheekErrors.length > 0">
      <p class="text-slate-500">Los de volgende problemen op om te kunnen exporteren</p>
      <div class="" v-for="(value, key) in hypotheekErrors" :key="key">
        <p class="text-sm text-red-500">
          {{ value.message }}
        </p>
      </div>
    </div>
  </baseCard>
</template>

<script>
import { computed, ref } from 'vue'
import filterHandler from '@/use/filterHandler'
import hypotheekBox from '@/components/extended/hypotheekBox.vue'
import VueHighcharts from '@/use/highCharts'

export default {
  props: {
    huidig: Object,
    nieuw: Object,
    huidig20: Object,
    nieuw20: Object,
    maatregelenHandler: Object,
    hypotheekHandler: Object,
    surveyDetails: Object,
    grafiekenHandler: Object,
  },
  setup(props) {
    const useFilter = filterHandler()
    const root = ref(null)

    const showBerekening = computed(() => props.surveyDetails.showBerekening.value)
    const loadingState = computed(() => props.surveyDetails.loadHandler.states.get_records)
    const uniekeKlimaatzones = computed(() => props.surveyDetails.uniekeKlimaatzones.value)
    const hypotheekErrors = computed(() => props.hypotheekHandler.errors.value)

    return {
      useFilter,
      showBerekening,
      loadingState,
      uniekeKlimaatzones,
      hypotheekErrors,
      root,
    }
  },
  components: {
    hypotheekBox,
    VueHighcharts,
  },
}
</script>
