/**
 * Handler for generating the afmelding URL with parameters from survey data
 */
export default function afmeldingUrlHandler(
  surveyData,
  formulaHuidig,
  formulaNieuw,
  hypotheek,
  userName,
  maatregelenHandler
) {
  // Base URL
  const baseUrl = 'https://duurzaam-hogeland-contact.nl/energieadvies-afmelding/'

  // Create URLSearchParams object for proper URL encoding
  const params = new URLSearchParams()

  // Map survey data to URL parameters
  if (surveyData) {
    console.log(surveyData)
    // Basic information
    params.append('email', surveyData.email || '')
    params.append('postcode', surveyData.zip || '')
    params.append('huisnr', surveyData.address || '')
    params.append('telefoon', surveyData.phone || '')

    // Format date if available
    if (surveyData.date_created) {
      const date = new Date(surveyData.date_created)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      params.append('datumgesprek', `${day}/${month}/${year}`)
    } else {
      params.append('datumgesprek', '')
    }

    // Advisor information
    params.append('voornaamadv', userName || surveyData.name || '')
    params.append('achternaamadv', surveyData.advisor_last_name || '')

    // Property details
    console.log(surveyData.soort_woning)
    params.append('status', surveyData.config.soort_woning.monumentale_status || '')
  }

  // Measures information
  console.log('Maatregelen:', surveyData.config.maatregelen)
  if (surveyData.config.maatregelen) {
    /*
     * Complete list of measure parameters for the URL:
     * - dak, dakm2
     * - zolder, m2zolder
     * - spouw, spouwm2
     * - gevel, gevelm2
     * - vloer, vloerm2
     * - bodem, bodemm2
     * - glas, typeglas, m2glas07, m2glas12
     * - deuren, deurenm2
     * - ventilatie
     * - opwekker
     * - afgifte
     * - elektra
     * - zonnepanelen, aantalpanelen
     */

    // Check for measures in each category and set parameters accordingly
    const dakMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('dakisolatie')
    )
    params.append('dak', dakMeasures.length > 0 ? 'ja' : 'nee')
    if (dakMeasures.length > 0) {
      params.append('dakm2', dakMeasures[0].hoeveelheid || '')
    } else {
      params.append('dakm2', '')
    }

    const zolderMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('zolder')
    )
    params.append('zolder', zolderMeasures.length > 0 ? 'ja' : 'nee')
    if (zolderMeasures.length > 0) {
      params.append('m2zolder', zolderMeasures[0].hoeveelheid || '')
    } else {
      params.append('m2zolder', '')
    }

    const spouwMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('spouwmuur')
    )
    params.append('spouw', spouwMeasures.length > 0 ? 'ja' : 'nee')
    if (spouwMeasures.length > 0) {
      params.append('spouwm2', spouwMeasures[0].hoeveelheid || '')
    } else {
      params.append('spouwm2', '')
    }

    const gevelMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('gevel')
    )
    params.append('gevel', gevelMeasures.length > 0 ? 'ja' : 'nee')
    if (gevelMeasures.length > 0) {
      params.append('gevelm2', gevelMeasures[0].hoeveelheid || '')
    } else {
      params.append('gevelm2', '')
    }

    const vloerMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('vloer')
    )
    params.append('vloer', vloerMeasures.length > 0 ? 'ja' : 'nee')
    if (vloerMeasures.length > 0) {
      params.append('vloerm2', vloerMeasures[0].hoeveelheid || '')
    } else {
      params.append('vloerm2', '')
    }

    const bodemMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('bodem')
    )
    params.append('bodem', bodemMeasures.length > 0 ? 'ja' : 'nee')
    if (bodemMeasures.length > 0) {
      params.append('bodemm2', bodemMeasures[0].hoeveelheid || '')
    } else {
      params.append('bodemm2', '')
    }

    const glasMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('glas')
    )
    params.append('glas', glasMeasures.length > 0 ? 'ja' : 'nee')

    if (glasMeasures.length > 0) {
      // Check which type of glass is being used
      const hasHRPlusPlus = glasMeasures.some((m) => m.categorie && m.categorie.toLowerCase().includes('hr++'))
      const hasTriple = glasMeasures.some((m) => m.categorie && m.categorie.toLowerCase().includes('triple'))

      // Set typeglas based on which types are present
      if (hasHRPlusPlus && hasTriple) {
        params.append('typeglas', 'beide')
      } else if (hasHRPlusPlus) {
        params.append('typeglas', '1.2')
      } else if (hasTriple) {
        params.append('typeglas', '0.7')
      } else {
        params.append('typeglas', '') // No specific type identified
      }

      // Set the appropriate glass quantities
      const hrPlusPlusMeasures = glasMeasures.filter((m) => m.categorie && m.categorie.toLowerCase().includes('hr++'))

      const tripleMeasures = glasMeasures.filter((m) => m.categorie && m.categorie.toLowerCase().includes('triple'))

      params.append('m2glas12', hrPlusPlusMeasures.length > 0 ? hrPlusPlusMeasures[0].hoeveelheid || '' : '')
      params.append('m2glas07', tripleMeasures.length > 0 ? tripleMeasures[0].hoeveelheid || '' : '')
    } else {
      params.append('typeglas', '')
      params.append('m2glas07', '')
      params.append('m2glas12', '')
    }

    const deurenMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('deur')
    )
    params.append('deuren', deurenMeasures.length > 0 ? 'ja' : 'nee')
    if (deurenMeasures.length > 0) {
      params.append('deurenm2', deurenMeasures[0].hoeveelheid || '')
    } else {
      params.append('deurenm2', '')
    }

    const ventilatieMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('ventilatie')
    )
    params.append('ventilatie', ventilatieMeasures.length > 0 ? 'ja' : 'nee')

    const opwekkerMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('warmteopwekker')
    )
    params.append('opwekker', opwekkerMeasures.length > 0 ? 'ja' : 'nee')

    const afgifteMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('afgifte')
    )
    params.append('afgifte', afgifteMeasures.length > 0 ? 'ja' : 'nee')

    const elektraMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('elektra')
    )
    params.append('elektra', elektraMeasures.length > 0 ? 'ja' : 'nee')

    const zonnepanelenMeasures = surveyData.config.maatregelen.filter(
      (m) => m.categorie && m.categorie.toLowerCase().includes('zonnepanelen')
    )
    params.append('zonnepanelen', zonnepanelenMeasures.length > 0 ? 'ja' : 'nee')
    if (zonnepanelenMeasures.length > 0) {
      params.append('aantalpanelen', zonnepanelenMeasures[0].hoeveelheid || '')
    } else {
      params.append('aantalpanelen', '')
    }
  }

  console.log(hypotheek)
  console.log(maatregelenHandler?.costs?.value)

  // Financial information
  if (maatregelenHandler?.costs?.value) {
    // Use maatregelenHandler for financial information where available
    params.append('totaalinv', maatregelenHandler.costs.value.totaal_kosten?.toString() || '')
    params.append('totaalsub', maatregelenHandler.costs.value.totaal_subsidie?.toString() || '')
    params.append('terugjaren', maatregelenHandler.costs.value.terugverdientijd?.toString() || '')
    // Calculate monthly savings as the difference between current and new monthly costs
    const monthlySavings =
      (maatregelenHandler.costs.value.energiekosten_per_maand_eerste_jaar_huidig || 0) -
      (maatregelenHandler.costs.value.energiekosten_per_maand_eerste_jaar_nieuw || 0)
    params.append('besparingmaand', monthlySavings.toString() || '')
  } else if (hypotheek) {
    // Fallback to hypotheek if maatregelenHandler is not available
    params.append('totaalinv', hypotheek.totaalinv || '')
    params.append('totaalsub', hypotheek.totaalsub || '')
    params.append('terugjaren', hypotheek.terugjaren || '')
    params.append('besparingmaand', hypotheek.besparingmaand || '')
  }

  // Calculation results
  if (maatregelenHandler?.costs?.value) {
    // Use maatregelenHandler for CO2 and costs where available
    params.append(
      'co2huidig',
      maatregelenHandler.costs.value.co2_uitstoot_per_jaar_huidig?.toLocaleString('nl-NL') || ''
    )
    params.append('co2nieuw', maatregelenHandler.costs.value.co2_uitstoot_per_jaar_nieuw?.toLocaleString('nl-NL') || '')
    params.append(
      'huidigekosten',
      maatregelenHandler.costs.value.energiekosten_per_maand_eerste_jaar_huidig?.toString() || ''
    )
    params.append(
      'nieuwekosten',
      maatregelenHandler.costs.value.energiekosten_per_maand_eerste_jaar_nieuw?.toString() || ''
    )
    // Still use formulaHuidig and formulaNieuw for electricity and gas

    if (formulaHuidig && formulaNieuw) {
      console.log(formulaHuidig)
      params.append('stroomoud', formulaHuidig.verbruik_totaal_kwh?.toLocaleString('nl-NL') || '')
      params.append('stroomnieuw', formulaNieuw.verbruik_totaal_kwh?.toLocaleString('nl-NL') || '')
      params.append('gasoud', formulaHuidig.verbruik_totaal_m3?.toLocaleString('nl-NL') || '')
      params.append('gasnieuw', formulaNieuw.verbruik_totaal_m3?.toLocaleString('nl-NL') || '')
    }
  } else if (formulaHuidig && formulaNieuw) {
    // Fallback to formulaHuidig and formulaNieuw if maatregelenHandler is not available
    params.append('co2huidig', formulaHuidig.co2 || '')
    params.append('co2nieuw', formulaNieuw.co2 || '')
    params.append('stroomoud', formulaHuidig.electricity || '')
    params.append('stroomnieuw', formulaNieuw.electricity || '')
    params.append('gasoud', formulaHuidig.gas || '')
    params.append('gasnieuw', formulaNieuw.gas || '')
    params.append('huidigekosten', formulaHuidig.costs || '')
    params.append('nieuwekosten', formulaNieuw.costs || '')
  }

  // Additional parameters
  params.append('opmerking', '')
  params.append('opvolging', 'nee')
  params.append('afhaken', 'Anders overige')
  params.append('toelichting', '')

  // Return the complete URL
  return `${baseUrl}?${params.toString()}`
}
