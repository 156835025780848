<template>
  <div v-if="huidig && nieuw">
    <p class="text-l text-slate-500 mb-4 font-bold">Berekening</p>

    <div class="flex flex-row text-l text-slate-500 font-bold mb-4">
      <p class="basis-1/3"></p>
      <p class="basis-1/3">Huidig</p>
      <p class="basis-1/3">Nieuw</p>
    </div>

    <div class="flex flex-row text-sm text-slate-500" v-for="(record, index) in fieldconfig" :key="index">
      <p v-if="record.type === 'subtitle'" class="my-4 text-l font-bold">{{ record.label }}</p>
      <p class="basis-1/3" v-if="record.type === 'normal'">{{ record.label }}</p>
      <p class="basis-1/3" v-if="record.type === 'normal'">
        {{ useFilter.filter(huidig.result.data_total[record.key], record.filter) }} {{ record.unit }}
      </p>
      <p class="basis-1/3" v-if="record.type === 'normal'">
        {{ useFilter.filter(nieuw.result.data_total[record.key], record.filter) }} {{ record.unit }}
      </p>
    </div>

    <!-- klimaatzones -->
    <!-- regular -->
    <base-collapse
      class="mb-2 w-full text-sm text-slate-500"
      v-for="(klimaatzoneNaam, klimaatzoneIndex) in surveyDetails.uniekeKlimaatzones.value"
      :key="klimaatzoneIndex"
    >
      <!-- header -->
      <template v-slot:header>
        <div class="flex flex-row">
          <!-- <span><featherIcon class="w-5 h-5 mr-1" icon="BarChart2Icon" /></span> -->
          <span class="mr-2">{{ klimaatzoneNaam }}</span>
        </div>
      </template>
      <template v-slot:header-right>
        <div class="mr-2"></div>
      </template>

      <!-- body -->
      <template v-slot:body>
        <div
          class="flex flex-row text-sm text-slate-500"
          v-for="(record, index) in fieldConfigKlimaatzone"
          :key="index"
        >
          <p v-if="record.type === 'subtitle'" class="my-4 text-l font-bold">{{ record.label }}</p>
          <p class="basis-1/3" v-if="record.type === 'normal'">{{ record.label }}</p>

          <div v-if="klimaatzoneNaam in huidig.result.data_total.klimaatzones" class="basis-1/3">
            <p v-if="record.type === 'normal'">
              {{ useFilter.filter(huidig.result.data_total.klimaatzones[klimaatzoneNaam][record.key], record.filter) }}
              {{ record.unit }}
            </p>
          </div>
          <div v-else class="basis-1/3">
            <p v-if="record.type === 'normal'">-</p>
          </div>

          <div v-if="klimaatzoneNaam in nieuw.result.data_total.klimaatzones" class="basis-1/3">
            <p v-if="record.type === 'normal'">
              {{ useFilter.filter(nieuw.result.data_total.klimaatzones[klimaatzoneNaam][record.key], record.filter) }}
              {{ record.unit }}
            </p>
          </div>
          <div v-else class="basis-1/3">
            <p v-if="record.type === 'normal'">-</p>
          </div>
        </div>
      </template>
    </base-collapse>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    huidig: {
      type: Object,
      default: () => {},
    },
    nieuw: {
      type: Object,
      default: () => {},
    },
    surveyDetails: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useFilter = filterHandler()

    const fieldConfigKlimaatzone = [
      {
        label: 'Transmissie',
        type: 'subtitle',
      },
      {
        label: 'Transmissie buitenlucht',
        key: 'transmissie_buitenlucht',
        unit: 'W/K',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Transmissie grond',
        key: 'transmissie_grond',
        unit: 'W/K',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Transmissie kruipruimte',
        key: 'transmissie_kruipruimte',
        unit: 'W/K',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Transmissie AOR',
        key: 'transmissie_aor',
        unit: 'W/K',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Infiltratie',
        type: 'subtitle',
      },
      {
        label: 'Natuurlijke ventilatie',
        key: 'natuurlijke_ventilatie',
        unit: '',
        type: 'normal',
      },
      {
        label: 'Winddrukcoëfficient',
        key: 'winddruk_coefficient',
        unit: '',
        type: 'normal',
      },
      {
        label: 'Qv;10;kar',
        key: 'qv_10_kar',
        unit: 'dm³/s*m²',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Qv;10;kar type',
        key: 'qv_10_kar_type',
        unit: '',
        type: 'normal',
      },
      {
        label: 'Qv;10;inf',
        key: 'qv_10_inf',
        unit: 'dm³/s',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Luchtdoorlatendheidcoëfficiënt infiltratie',
        key: 'luchtdoorlatendheidcoëfficiënt_infiltratie',
        unit: 'dm³/(s*Pa^1/n)',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Ventilatie',
        type: 'subtitle',
      },
      {
        label: 'Qv;10;vent;max',
        key: 'qv_10_max',
        unit: 'dm³/s',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Qv;10;vent',
        key: 'qv_10_vent',
        unit: 'dm³/s',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Luchtdoorlatendheidcoëfficiënt ventilatie',
        key: 'luchtdoorlatendheidcoëfficiënt_ventilatie',
        unit: 'dm³/(s*Pa^1/n)',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Mechanische ventilatie',
        key: 'mechanische_ventilatie',
        unit: 'm3/h',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'WTW ventilatie rendement',
        key: 'rendement_ventilatie_wtw',
        unit: '%',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'WTW ventilatie',
        key: 'wtw_ventilatie',
        unit: 'm3/h',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Totalen',
        type: 'subtitle',
      },
      {
        label: 'Totaal transmissie',
        key: 'totaal_transmissie_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Totaal infiltratie',
        key: 'totaal_infiltratie_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Totaal ventilatie',
        key: 'totaal_ventilatie_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Totaal zoninstraling',
        key: 'totaal_zoninstraling_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verwarmingsvermogen',
        type: 'subtitle',
      },
      {
        label: 'Transmissie buitenlucht',
        key: 'vermogen_transmissie_buitenlucht',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Transmissie grond',
        key: 'vermogen_transmissie_grond',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Transmissie kruipruimte',
        key: 'vermogen_transmissie_kruipruimte',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Transmissie AOR',
        key: 'vermogen_transmissie_aor',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },

      {
        label: 'Infiltratie',
        key: 'infiltratie_kw',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Ventilatie',
        key: 'ventilatie_kw',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Opwarmvermogen',
        key: 'opwarming_kw',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Veiligheidsmarge vermogen',
        key: 'veiligheidsmarge_kw',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Totaal vermogen',
        key: 'totaal_kw',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Afgiftevermogen',
        type: 'subtitle',
      },
      {
        label: 'Benodigd afgiftevermogen',
        key: 'benodigd_afgiftevermogen',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Beschikbaar afgiftevermogen',
        key: 'beschikbaar_afgiftevermogen',
        unit: 'kW',
        type: 'normal',
        filter: 'round2',
      },
    ]

    const fieldconfig = [
      {
        label: 'Oppervlakte bouwdelen (bruto)',
        key: 'totaal_oppervlakte_bouwdelen_bruto',
        unit: 'm2',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Oppervlakte bouwdelen (netto)',
        key: 'totaal_oppervlakte_bouwdelen_netto',
        unit: 'm2',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Oppervlakte onderbrekingen',
        key: 'totaal_oppervlakte_deelvlakken',
        unit: 'm2',
        type: 'normal',
        filter: 'round2',
      },
      {
        label: 'Verbruiken',
        type: 'subtitle',
      },
      {
        label: 'Rendement distributiesysteem',
        key: 'rendement_distributiesysteem',
        unit: '%',
        type: 'normal',
      },
      {
        label: 'Verbruik verwarming elektrisch',
        key: 'sum_verwarming_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik warm tapwater elektrisch',
        key: 'tapwater_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik verwarming gas',
        key: 'sum_verwarming_m3',
        unit: 'm3',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik warm tapwater gas',
        key: 'tapwater_m3',
        unit: 'm3',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik verwarming warmte',
        key: 'sum_verwarming_gj',
        unit: 'GJ',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik warm tapwater warmte',
        key: 'tapwater_gj',
        unit: 'GJ',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verlies voorraadvat',
        key: 'verlies_voorraadvat_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik ventilatoren',
        key: 'ventilatie_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik hulpenergie verwarming',
        key: 'verbruik_hulpenergie_verwarming_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik koken elektrisch',
        key: 'verbruik_koken_electrisch',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Verbruik koken gas',
        key: 'verbruik_koken_gas',
        unit: 'm3',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Opbrengst zonnepanelen',
        key: 'opbrengst_zonnepanelen',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Eigen elektrisch verbruik',
        key: 'eigen_elektrisch_verbruik',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Totaal elektrisch',
        key: 'verbruik_totaal_kwh',
        unit: 'kWh',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Totaal gas',
        key: 'verbruik_totaal_m3',
        unit: 'm3',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Totaal warmte',
        key: 'verbruik_totaal_gj',
        unit: 'GJ',
        type: 'normal',
        filter: 'round',
      },
      {
        label: 'Klimaatzones',
        type: 'subtitle',
      },
    ]
    return {
      fieldconfig,
      fieldConfigKlimaatzone,
      useFilter,
    }
  },
}
</script>
