<template>
  <data-table :data="users" @row-click="viewUserOrganizations">
    <template #toolbar>
      <table-add-button @add="showAddModal = true" />
      <table-filters-button />
      <search-field />
    </template>

    <template #header>
      <table-header-cell field="name" label="Naam">Naam</table-header-cell>
      <table-header-cell field="email" label="E-mail">E-mail</table-header-cell>
      <table-header-cell field="actions" label="Acties" :filterable="false" :sortable="false">
        <div class="flex justify-center w-full">Acties</div>
      </table-header-cell>
    </template>

    <template #row="{ record }">
      <table-row-cell field="name">{{ record.name }}</table-row-cell>
      <table-row-cell field="email">{{ record.email }}</table-row-cell>
      <table-row-cell field="actions" class="flex flex-row justify-center">
        <table-delete-button @delete="deleteUser(record.id)" />
      </table-row-cell>
    </template>
  </data-table>

  <baseModal v-if="showAddModal" size="m" @close="closeModal" title="Add user">
    <form class="w-full flex flex-col">
      <baseInput v-model="name" label="Name" type="string" id="name" />
      <baseInput v-model="email" label="Email" type="email" id="email" />
      <baseInput v-model="password" label="Password" :password="true" type="password" id="password" />
      <baseSelectField v-model="selectedRole" :options="roleOptions" label="Role" zIndex="z-[1]" />
    </form>
    <template v-slot:buttons>
      <baseButton @action="addUser" :loading="addingUser" class="mr-2">Add</baseButton>
    </template>
  </baseModal>

  <!-- confirmation modal -->
  <confimationModal :handler="confirm" />

  <!-- user organizations and permissions modal -->
  <baseModal
    v-if="showOrganizationsModal"
    size="l"
    @close="closeOrganizationsModal"
    :title="`Gebruiker: ${selectedUser?.name || 'Gebruiker'}`"
  >
    <baseTabs :handler="modalTabs">
      <!-- Organizations Tab -->
      <template v-slot:panel-organisations>
        <data-table :data="userOrganizations" :loading="loadingOrganizations">
          <template #toolbar>
            <table-add-button @add="showAddOrganizationModal = true" />
            <table-filters-button />
            <search-field />
          </template>

          <template #header>
            <table-header-cell field="name" label="Naam">Naam</table-header-cell>
            <!-- <table-header-cell field="auth0_org_id" label="Organisatie ID">Organisatie ID</table-header-cell> -->
            <table-header-cell field="proces_tool" label="Proces Tool">Procestool</table-header-cell>
            <table-header-cell field="actions" label="Acties" :filterable="false" :sortable="false">
              <div class="flex justify-center w-full">Acties</div>
            </table-header-cell>
          </template>

          <template #row="{ record }">
            <table-row-cell field="name">{{ record.name }}</table-row-cell>
            <!-- <table-row-cell field="auth0_org_id">{{ record.auth0_org_id }}</table-row-cell> -->
            <table-row-cell field="proces_tool">{{ record.proces_tool ? 'Ja' : 'Nee' }}</table-row-cell>
            <table-row-cell field="actions" class="flex flex-row justify-center">
              <table-delete-button
                @delete="removeOrganization(record.id)"
                :loading="removingOrganization && selectedOrganizationToRemove === record.id"
              />
            </table-row-cell>
          </template>
        </data-table>
      </template>

      <!-- Permissions Tab -->
      <template v-slot:panel-permissions>
        <div class="my-6">
          <data-table :data="userPermissions" :loading="loadingPermissions">
            <template #toolbar>
              <table-add-button @add="showAddPermissionModal = true" />
              <table-filters-button />
              <search-field />
            </template>

            <template #header>
              <table-header-cell field="name" label="Permissie">Permissie</table-header-cell>
              <table-header-cell field="resource_server_name" label="Resource Server">
                Resource Server
              </table-header-cell>
              <table-header-cell field="source_type" label="Bron">Bron</table-header-cell>
              <table-header-cell field="actions" label="Acties" :filterable="false" :sortable="false">
                <div class="flex justify-center w-full">Acties</div>
              </table-header-cell>
            </template>
            <template #row="{ record }">
              <table-row-cell field="name">{{ record.permission_name }}</table-row-cell>
              <table-row-cell field="resource_server_name">
                {{ record.resource_server_name }}
              </table-row-cell>
              <table-row-cell field="source_type">
                <span
                  v-if="isDirectPermission(record)"
                  class="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
                >
                  Direct
                </span>
                <span v-else class="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">
                  Via rol: {{ getRoleName(record) }}
                </span>
              </table-row-cell>
              <table-row-cell field="actions" class="flex flex-row justify-center">
                <table-delete-button
                  v-if="isDirectPermission(record)"
                  @delete="removePermission(record)"
                  :loading="removingPermission && selectedPermissionToRemove === record.id"
                />
                <span v-else class="text-xs text-gray-500 italic">Niet verwijderbaar</span>
              </table-row-cell>
            </template>
          </data-table>
        </div>
      </template>
    </baseTabs>
  </baseModal>

  <!-- add organization modal -->
  <baseModal v-if="showAddOrganizationModal" size="m" @close="closeAddOrganizationModal" title="Add organizations">
    <form class="w-full flex flex-col">
      <data-table
        v-if="availableOrganizations.length > 0"
        :data="availableOrganizations"
        :selectable="true"
        ref="addOrganizationsTable"
      >
        <template #toolbar>
          <table-selection-button />
          <table-filters-button />
          <search-field />
        </template>

        <template #header>
          <table-select-all-checkbox />
          <table-header-cell field="name" label="Naam">Naam</table-header-cell>
          <table-header-cell field="proces_tool" label="Proces Tool">Procestool</table-header-cell>
        </template>

        <template #row="{ record }">
          <table-select-checkbox :record="record" />
          <table-row-cell field="name">{{ record.name }}</table-row-cell>
          <table-row-cell field="proces_tool">{{ record.proces_tool ? 'Ja' : 'Nee' }}</table-row-cell>
        </template>
      </data-table>
      <div v-else class="text-gray-500 italic mb-4">
        Alle beschikbare organisaties zijn al toegewezen aan deze gebruiker.
      </div>
    </form>
    <template v-slot:buttons>
      <baseButton
        @action="addOrganizations"
        :loading="loadingAddOrganizations"
        :disabled="availableOrganizations.length === 0"
        class="mr-2"
      >
        Toevoegen
      </baseButton>
    </template>
  </baseModal>

  <!-- add permission modal -->
  <baseModal v-if="showAddPermissionModal" size="s" @close="closeAddPermissionModal" title="Permissie toevoegen">
    <div v-if="availablePermissionsOptions.length === 0" class="text-gray-500 italic mb-4">
      Alle beschikbare permissies zijn al toegewezen aan deze gebruiker.
    </div>
    <baseSelectField
      v-model="selectedPermission"
      :options="availablePermissionsOptions"
      label="Permissie"
      class="w-full mb-4"
      :disabled="availablePermissionsOptions.length === 0"
    />
    <template v-slot:buttons>
      <baseButton
        @action="assignPermissionAndClose"
        :loading="assigningPermission"
        :disabled="availablePermissionsOptions.length === 0"
      >
        Toevoegen
      </baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import toastHandler from '@/use/toastHandler'
import confirmationHandler from '@/use/confirmationHandler'
import tabHandler from '@/use/tabHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

const useApi = apiHandler()
const useToast = toastHandler()
const confirm = confirmationHandler()

const showAddModal = ref(false)
const showOrganizationsModal = ref(false)
const showAddPermissionModal = ref(false)
const showAddOrganizationModal = ref(false)
const users = ref([])
const roles = ref([])
const selectedUser = ref(null)
const userOrganizations = ref([])
const loadingOrganizations = ref(false)
const allOrganizations = ref([])
const availableOrganizations = ref([])
const addOrganizationsTable = ref(null)
const loadingAddOrganizations = ref(false)
const removingOrganization = ref(false)
const selectedOrganizationToRemove = ref(null)

// Permissions state
const modalTabs = tabHandler()
const userPermissions = ref([])
const availablePermissions = ref([])
const selectedPermission = ref(null)
const selectedPermissionToRemove = ref(null)
const loadingPermissions = ref(false)
const assigningPermission = ref(false)
const removingPermission = ref(false)

const name = ref('')
const email = ref('')
const password = ref('')
const selectedRole = ref(null)
const addingUser = ref(false)

const roleOptions = computed(() =>
  roles.value.map((role) => ({
    value: role.id,
    label: role.name,
  }))
)

// Computed property for permission options
const availablePermissionsOptions = computed(() => {
  // Extract all permission names that the user already has
  const currentPermissionNames = userPermissions.value.map((p) => p.permission_name)

  // Filter out permissions that the user already has
  return availablePermissions.value
    .filter((p) => !currentPermissionNames.includes(p.name))
    .map((p) => ({
      value: p.id,
      label: p.description || p.name,
    }))
})

// Check if a permission is directly assigned
function isDirectPermission(permission) {
  return permission.sources && permission.sources.some((source) => source.source_type === 'DIRECT')
}

// Get the role name for a role-based permission
function getRoleName(permission) {
  if (!permission.sources) return ''

  const roleSource = permission.sources.find((source) => source.source_type === 'ROLE')
  return roleSource ? roleSource.source_name : ''
}

onMounted(() => {
  getUsers()
  getRoles()
  getAllOrganizations()
})

async function getUsers() {
  const { data } = await useApi.request('get', 'admin/users')
  users.value = data
}

async function getRoles() {
  const { data } = await useApi.request('get', 'admin/roles')
  roles.value = data
  if (data.length > 0) {
    selectedRole.value = data[0].id
  }
}

async function closeModal() {
  showAddModal.value = false
  name.value = ''
  email.value = ''
  password.value = ''
  selectedRole.value = roles.value.length > 0 ? roles.value[0].id : null
}

async function addUser() {
  addingUser.value = true
  try {
    const payload = {
      user: {
        name: name.value,
        email: email.value,
        password: password.value,
      },
      role_id: selectedRole.value,
    }
    await useApi.request('post', `admin/users`, payload)

    getUsers()
    closeModal()
  } catch (error) {
    useToast.addToast(`Error adding user:\n${error.response.data.detail.replaceAll('. ', '.\n')}`, 'danger', 10000)
    console.error('Error adding user', error)
  } finally {
    addingUser.value = false
  }
}

async function deleteUser(userId) {
  const ok = await confirm.open({
    title: 'Gebruiker verwijderen',
    message: 'Weet je zeker dat je deze gebruiker wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.',
  })
  if (!ok) return

  try {
    await useApi.request('delete', `admin/users/${userId}`)
    getUsers()
    useToast.addToast('User deleted successfully', 'success')
  } catch (error) {
    useToast.addToast(`Error deleting user:\n${error.response.data.detail}`, 'danger', 10000)
    console.error('Error deleting user', error)
  }
}

// Initialize tabs for the modal
function initModalTabs() {
  modalTabs.create({
    tabs: [
      { id: 'organisations', label: 'Organisaties', active: true },
      { id: 'permissions', label: 'Permissies', active: true },
    ],
  })
}

async function viewUserOrganizations(user) {
  selectedUser.value = user
  showOrganizationsModal.value = true
  initModalTabs()

  // Fetch data for both tabs
  await Promise.all([getUserOrganizations(user.id), getUserPermissions(user.id), getAllPermissions()])

  // Prepare available organizations for the add organization modal
  showAddOrganization()
}

async function getUserOrganizations(userId) {
  loadingOrganizations.value = true
  try {
    const { data } = await useApi.request('get', `admin/users/${userId}/organizations`)
    userOrganizations.value = data
  } catch (error) {
    useToast.addToast(
      `Error fetching user organizations:\n${error.response?.data?.detail || error.message}`,
      'danger',
      10000
    )
    console.error('Error fetching user organizations', error)
  } finally {
    loadingOrganizations.value = false
  }
}

// Fetch all available permissions
async function getAllPermissions() {
  try {
    const { data } = await useApi.request('get', 'admin/permissions')
    availablePermissions.value = data
  } catch (error) {
    useToast.addToast(`Error fetching permissions:\n${error.response?.data?.detail || error.message}`, 'danger', 10000)
    console.error('Error fetching permissions', error)
  }
}

// Fetch user permissions
async function getUserPermissions(userId) {
  loadingPermissions.value = true
  try {
    const { data } = await useApi.request('get', `admin/users/${userId}/permissions`)
    userPermissions.value = data
  } catch (error) {
    useToast.addToast(
      `Error fetching user permissions:\n${error.response?.data?.detail || error.message}`,
      'danger',
      10000
    )
    console.error('Error fetching user permissions', error)
  } finally {
    loadingPermissions.value = false
  }
}

// Assign permission to user and close modal
async function assignPermissionAndClose() {
  if (!selectedPermission.value) return

  assigningPermission.value = true
  try {
    const permission = availablePermissions.value.find((p) => p.id === selectedPermission.value)

    // Format the permission object according to the API requirements
    const formattedPermission = {
      permission_name: permission.name,
      resource_server_identifier: permission.resource_server_name,
    }

    // Send the array directly without wrapping it in a permissions object
    await useApi.request('post', `admin/users/${selectedUser.value.id}/permissions`, [formattedPermission])

    await getUserPermissions(selectedUser.value.id)
    closeAddPermissionModal()
    useToast.addToast('Permissie toegevoegd', 'success')
  } catch (error) {
    useToast.addToast(`Error assigning permission:\n${error.response?.data?.detail || error.message}`, 'danger', 10000)
    console.error('Error assigning permission', error)
  } finally {
    assigningPermission.value = false
  }
}

// Remove permission from user
async function removePermission(permission) {
  // Only allow removing direct permissions
  if (!isDirectPermission(permission)) {
    useToast.addToast('Permissies via rollen kunnen niet direct worden verwijderd', 'warning', 5000)
    return
  }

  selectedPermissionToRemove.value = permission.id
  removingPermission.value = true
  try {
    // Use query parameters instead of a request body
    const url = `admin/users/${selectedUser.value.id}/permissions?permission_name=${encodeURIComponent(
      permission.permission_name
    )}&resource_server_identifier=${encodeURIComponent(permission.resource_server_name)}`

    await useApi.request('delete', url)

    await getUserPermissions(selectedUser.value.id)
    useToast.addToast('Permissie verwijderd', 'success')
  } catch (error) {
    useToast.addToast(`Error removing permission:\n${error.response?.data?.detail || error.message}`, 'danger', 10000)
    console.error('Error removing permission', error)
  } finally {
    removingPermission.value = false
    selectedPermissionToRemove.value = null
  }
}

// Get all organizations
async function getAllOrganizations() {
  try {
    const { data } = await useApi.request('get', 'admin/organizations')
    allOrganizations.value = data
  } catch (error) {
    console.error('Error fetching organizations:', error)
  }
}

// Show add organization modal and filter available organizations
function showAddOrganization() {
  // Filter out organizations the user is already part of
  availableOrganizations.value = allOrganizations.value.filter(
    (org) => !userOrganizations.value.some((userOrg) => userOrg.id === org.id)
  )
  // showAddOrganizationModal.value = true
}

// Close the add organization modal
function closeAddOrganizationModal() {
  showAddOrganizationModal.value = false
}

// Add selected organizations to the user
async function addOrganizations() {
  if (!addOrganizationsTable.value || !addOrganizationsTable.value.tableState) return

  loadingAddOrganizations.value = true
  const userId = selectedUser.value.id
  const orgIds = addOrganizationsTable.value.tableState.selectedRecords.value.map((org) => org.id)

  try {
    await useApi.request('post', `admin/users/${userId}/organizations`, { organizations: orgIds })
    await getUserOrganizations(userId)
    closeAddOrganizationModal()
    useToast.addToast('Organizations added successfully', 'success')

    // Update available organizations
    showAddOrganization()
  } catch (error) {
    useToast.addToast(`Error adding organizations:\n${error.response?.data?.detail || error.message}`, 'danger', 10000)
    console.error('Error adding organizations:', error)
  } finally {
    loadingAddOrganizations.value = false
  }
}

// Remove organization from user
async function removeOrganization(organizationId) {
  selectedOrganizationToRemove.value = organizationId
  removingOrganization.value = true

  try {
    const userId = selectedUser.value.id
    // Use the correct query string format
    const url = `admin/users/${userId}/organizations?organizations=${encodeURIComponent(organizationId)}`

    await useApi.request('delete', url)
    await getUserOrganizations(userId)
    useToast.addToast('Organization removed successfully', 'success')

    // Update available organizations
    showAddOrganization()
  } catch (error) {
    useToast.addToast(`Error removing organization:\n${error.response?.data?.detail || error.message}`, 'danger', 10000)
    console.error('Error removing organization:', error)
  } finally {
    removingOrganization.value = false
    selectedOrganizationToRemove.value = null
  }
}

function closeOrganizationsModal() {
  showOrganizationsModal.value = false
  showAddOrganizationModal.value = false
  selectedUser.value = null
  userOrganizations.value = []
  userPermissions.value = []
  availablePermissions.value = []
  availableOrganizations.value = []
  selectedPermission.value = null
  showAddPermissionModal.value = false
}

// Close the add permission modal
function closeAddPermissionModal() {
  showAddPermissionModal.value = false
  selectedPermission.value = null
}
</script>
