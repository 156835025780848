<template>
  <div class="h-full min-h-0">
    <div class="flex h-full flex-col overflow-y-auto">
      <div class="flex flex-row justify-between">
        <basePageTitle :title="useContentGuard.organisation.value" subtitle="Projecten" />
      </div>

      <div class="flex-grow py-10">
        <data-table :data="useProject.records" @row-click="tableActionProject">
          <template #toolbar>
            <table-add-button @add="openAddProject" />
            <table-filters-button />
            <search-field />
          </template>

          <template #header>
            <table-header-cell field="name" label="Name">Naam</table-header-cell>
            <table-header-cell field="description" label="Description">Omschrijving</table-header-cell>
            <table-header-cell field="start_date" label="Start date" :filterable="false">Startdatum</table-header-cell>
            <table-header-cell field="end_date" label="End date" :filterable="false">Einddatum</table-header-cell>
            <table-header-cell field="actions" label="Actions" :filterable="false" :sortable="false">
              Acties
            </table-header-cell>
          </template>

          <template #row="{ record }">
            <table-row-cell field="name">{{ record.name }}</table-row-cell>
            <table-row-cell field="description">{{ record.description }}</table-row-cell>
            <table-row-cell field="start_date">{{ $filter.dateFromEpochDay(record.start_date) }}</table-row-cell>
            <table-row-cell field="end_date">{{ $filter.dateFromEpochDay(record.end_date) }}</table-row-cell>
            <table-row-cell field="actions">
              <table-delete-button @delete="onDelete(record.id)" />
            </table-row-cell>
          </template>
        </data-table>
      </div>

      <baseModal
        v-if="useProject.showAdd.value"
        @close="useProject.closeAdd()"
        :title="$translate('Add')"
        @enter="useProject.onEnter()"
      >
        <baseForm :handler="useProject.addForm" :loading="useProject.loadHandler.states['add_record']" />
        <template #buttons>
          <baseButton @action="useProject.addRecord()" :loading="useProject.loadHandler.states['add_record']">
            {{ $translate('Add') }}
          </baseButton>
        </template>
      </baseModal>

      <!-- confirmation modal -->
      <confimationModal :handler="confirm" />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import contentGuardHandler from '@/use/contentGuardHandler'
import projectHandler from '@/use/projectHandler'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

const router = useRouter()
const useContentGuard = contentGuardHandler()
const useProject = projectHandler()
const confirm = confirmationHandler()

function tableActionProject(payload) {
  router.push({
    name: 'project',
    params: { projectId: payload['id'] },
  })
}

function openAddProject() {
  useProject.openAdd()
}

async function onDelete(id) {
  const ok = await confirm.open({
    title: 'Project verwijderen',
    message: 'Weet je zeker dat je dit project wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.',
  })
  if (ok) {
    useProject.deleteRecord(id)
  }
}

onMounted(() => {
  useProject.getRecords()
})
</script>
