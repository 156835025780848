<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <!-- <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <basePageTitle :title="useContentGuard.organisation.value" />
            </div>
          </div> -->
        </div>

        <!-- content columns -->
        <div class="flex flex-row min-h-0">
          <div class="basis-full">
            <!-- table -->
            <data-table :data="useSurvey.records.value" @row-click="tableAction">
              <template #toolbar>
                <table-add-button @add="useSurvey.openAdd" />
                <table-filters-button />
                <search-field />
              </template>

              <template #header>
                <table-header-cell field="description" label="Description">Omschrijving</table-header-cell>
                <table-header-cell field="name" label="Name">Naam</table-header-cell>
                <table-header-cell field="date_created" label="Date Created" :filterable="false"
                  >Aanmaakdatum</table-header-cell
                >
                <table-header-cell field="actions" label="Actions" :filterable="false" :sortable="false">
                  <div class="flex justify-center w-full">Acties</div>
                </table-header-cell>
              </template>

              <template #row="{ record }">
                <table-row-cell field="description">{{ record.description }}</table-row-cell>
                <table-row-cell field="name">{{ record.name }}</table-row-cell>
                <table-row-cell field="date_created">{{
                  $filter.dateFromEpochDay(record.date_created)
                }}</table-row-cell>
                <table-row-cell field="actions" class="flex flex-row justify-center gap-2">
                  <table-export-button-record @export="() => useSurvey.exportRecord(record)" />
                  <table-duplicate-button @duplicate="() => useSurvey.duplicateRecord(record.id)" />
                  <table-edit-button @edit="() => onOpenEdit(record)" />
                  <table-delete-button @delete="() => onDelete(record.id)" />
                </table-row-cell>
              </template>
            </data-table>

            <!-- add modal -->
            <baseModal
              v-if="useSurvey.showAdd.value"
              @close="useSurvey.closeAdd()"
              :title="$translate('Add')"
              @enter="onEnter()"
            >
              <baseForm :handler="useSurvey.addForm" :loading="useSurvey.loadHandler.states['add_record']" />
              <template v-slot:buttons>
                <!-- add -->
                <baseButton @action="useSurvey.addRecord()" :loading="useSurvey.loadHandler.states[`add_record`]">
                  {{ $translate('Add') }}
                </baseButton>

                <!-- import woningtool -->
                <baseButton
                  class="mr-2"
                  @action="openFilePicker('woningtool')"
                  :loading="useSurvey.loadHandler.states[`import_record`]"
                >
                  <div class="flex felx-row">
                    <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                    <span>Woningtool</span>
                  </div>
                </baseButton>

                <!-- Import XML Files -->
                <baseButton
                  v-if="useContentGuard.canSee('monitor:import')"
                  class="mr-2"
                  @action="openImportXMLModal()"
                  :loading="useSurvey.loadHandler.states[`import_record`]"
                >
                  <div class="flex felx-row">
                    <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                    <span>Monitor</span>
                  </div>
                </baseButton>
              </template>
            </baseModal>

            <!-- edit modal -->
            <baseModal v-if="useSurvey.showEdit.value" @close="useSurvey.closeEdit()" :title="$translate('Edit')">
              <baseForm :handler="useSurvey.editForm" :loading="useSurvey.loadHandler.states['edit_record']" />
              <template v-slot:buttons>
                <baseButton @action="useSurvey.editRecord()" :loading="useSurvey.loadHandler.states[`edit_record`]">
                  {{ $translate('Edit') }}
                </baseButton>
              </template>
            </baseModal>

            <!-- Import XML files modal -->
            <baseModal v-if="showImportXMLModal" @close="closeImportXMLModal" title="Import monitorbestanden">
              <baseForm :handler="importXMLForm" :loading="useSurvey.loadHandler.states['import_record']" />
              <template v-slot:buttons>
                <baseButton @action="confirmImportXML" :loading="useSurvey.loadHandler.states[`import_record`]">
                  {{ $translate('Import') }}
                </baseButton>
              </template>
            </baseModal>

            <!-- confirmation modal -->
            <confimationModal :handler="confirm" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import surveyHandler from '@/use/surveyHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import vabiHandler from '@/use/vabiHandler.js'
import toastHandler from '@/use/toastHandler'
import formHandler from '@/use/formHandler'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

const router = useRouter()
const confirm = confirmationHandler()
const useContentGuard = contentGuardHandler()
const useVabi = vabiHandler()
const useToast = toastHandler()
const useSurvey = surveyHandler()

function tableAction(payload) {
  router.push({
    name: 'survey',
    params: { objectId: payload['id'] },
  })
}

function onEnter() {
  console.log('')
}

const showImportXMLModal = ref(false)

// Create a new form handler for XML file import
const importXMLForm = formHandler()
const importXMLFields = [
  {
    label: 'Huidige situatie XML',
    key: 'currentXML',
    type: 'file',
    accept: '.xml',
    required: false,
  },
  {
    label: 'Nieuwe situatie XML',
    key: 'newXML',
    type: 'file',
    accept: '.xml',
    required: false,
  },
  {
    label: 'Bron basisinformatie',
    key: 'basicInfoSource',
    type: 'select',
    options: [
      { value: 'current', label: 'Huidige situatie XML' },
      { value: 'new', label: 'Nieuwe situatie XML' },
    ],
    required: true,
  },
]

const openImportXMLModal = () => {
  showImportXMLModal.value = true
  importXMLForm.create({ type: 'add', fields: importXMLFields, data: { basicInfoSource: 'current' } })
}

const closeImportXMLModal = () => {
  showImportXMLModal.value = false
}

const confirmImportXML = async () => {
  useSurvey.loadHandler.setLoadingState('import_record', true)
  try {
    const currentXML = importXMLForm.data.value.currentXML
      ? await readFileAsText(importXMLForm.data.value.currentXML)
      : null
    const newXML = importXMLForm.data.value.newXML ? await readFileAsText(importXMLForm.data.value.newXML) : null
    const basicInfoSource = importXMLForm.data.value.basicInfoSource

    if (!currentXML && !newXML) {
      throw new Error('At least one XML file must be selected')
    }

    let parsedFile
    if (currentXML && newXML) {
      parsedFile = useVabi.parseTwo(currentXML, newXML, basicInfoSource)
    } else if (currentXML) {
      parsedFile = useVabi.parse(currentXML, 'current')
    } else {
      parsedFile = useVabi.parse(newXML, 'new')
    }

    Object.assign(useSurvey.addForm.data.value, parsedFile)
    useSurvey.addForm.update_errors()

    showImportXMLModal.value = false
    // useToast.addToast('XML file(s) imported successfully', 'success')
  } catch (error) {
    useToast.addToast(error.message || 'Error importing XML file(s)', 'danger')
    console.error(error)
  } finally {
    useSurvey.loadHandler.setLoadingState('import_record', false)
  }
}

const readFileAsText = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => resolve(event.target.result)
    reader.onerror = (error) => reject(error)
    reader.readAsText(file)
  })
}

const openFilePicker = (type) => {
  const fileTypeSelector = {
    woningtool: 'application/json',
    monitor: 'text/xml',
  }

  const fileInput = document.createElement('input')
  fileInput.type = 'file'
  fileInput.accept = fileTypeSelector[type]

  const handleFileSelection = (event) => {
    const file = event.target.files[0]
    processFile(file, type)
  }

  fileInput.addEventListener('change', handleFileSelection)
  fileInput.click()
}

const processFile = (file, type) => {
  const reader = new FileReader()

  reader.onload = (event) => {
    if (type === 'woningtool') {
      useSurvey.addForm.sync(JSON.parse(event.target.result))
    } else if (type === 'monitor') {
      try {
        const parsedFile = useVabi.parse(event.target.result, 'current')
        Object.assign(useSurvey.addForm.data.value, parsedFile)
        useSurvey.addForm.update_errors()
      } catch (error) {
        useToast.addToast('Kan monitoringsbestand niet inlezen', 'danger')
        console.log(error)
      }
    }
  }

  reader.readAsText(file)
}

async function onDelete(id) {
  const ok = await confirm.open({
    title: 'Opname verwijderen',
    message: 'Weet je zeker dat je deze opname wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.',
  })
  if (ok) {
    useSurvey.deleteRecord(id, null)
  }
}

function onOpenEdit(data) {
  useSurvey.openEdit(data)
}

onMounted(() => {
  useSurvey.getRecords()
})
</script>
